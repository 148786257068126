let version = null

let bodyVersion = document.body.getAttribute('ww-variant-version')
if (!!bodyVersion) version = bodyVersion

if (version == null) version = document.documentElement.getAttribute('data-app-version')

if (version == null && typeof Windows !== 'undefined') version = '20.0'
// convert version to a float. We are guaranteed that version numbers will
// always be floats, e.g. 18.1, 20.0.
if (version != null) version = parseFloat(version)

export default version
